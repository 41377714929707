<template>
  <div id="admin-real-estate-development-create" class="admin-view">
    <v-row no-gutters>
      <v-col md="12">
        <merge-external-broker />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import DefaultLayout from "@/components/layouts/DefaultLayout.vue";

export default {
  components: {
    MergeExternalBroker: () =>
      import("@/components/merge-external-broker/MergeExternalBroker.vue")
  },
  created() {
    this.$emit(`update:layout`, DefaultLayout);
  }
};
</script>
